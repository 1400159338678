/* Root styling: Adding global fonts and smoother transitions */
:root {
    --primary-bg: #1c1c1c;
    --card-bg: #2a2a2a;
    --input-bg: #3a3a3a;
    --highlight-color: #ff6b6b;
    --primary-text: #f1f1f1;
    --secondary-text: #b0b0b0;
    --success-bg: #d4edda;
    --success-text: #155724;
    --error-bg: #f8d7da;
    --error-text: #721c24;
    --button-bg: #ff4757;
    --button-hover-bg: #e84141;
  }
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    transition: all 0.3s ease;
  }
  
  /* Fullscreen Container */
  .container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #1c1c1c, #111);
    padding: 2rem;
  }
  
  /* Card for the form */
  .card {
    background-color: var(--card-bg);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    padding: 2rem;
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  /* Title Styling */
  .title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--primary-text);
    margin-bottom: 1rem;
  }
  
  /* Instructions Styling */
  .instructions {
    color: var(--secondary-text);
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .highlight {
    color: var(--highlight-color);
    font-weight: bold;
  }
  
  /* Form Layout */
  .form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  
  /* Input Styling */
  .input {
    width: 100%;
    padding: 1rem;
    border: none;
    border-radius: 8px;
    background-color: var(--input-bg);
    color: var(--primary-text);
    font-size: 1rem;
    outline: none;
  }
  
  .input:focus {
    box-shadow: 0 0 8px rgba(255, 105, 135, 0.5);
    border: 2px solid var(--highlight-color);
  }
  
  /* Submit Button */
  .submit-btn {
    width: 100%;
    padding: 1rem;
    background-color: var(--button-bg);
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: var(--button-hover-bg);
  }
  
  .submit-btn:disabled {
    background-color: #555;
    cursor: not-allowed;
  }
  
  /* Sub-instructions */
  .sub-instructions {
    font-size: 0.85rem;
    color: var(--secondary-text);
  }
  
  /* Success Message */
  .success-message {
    margin-top: 1rem;
    padding: 1rem;
    background-color: var(--success-bg);
    color: var(--success-text);
    border-radius: 8px;
  }
  
  .code {
    font-family: 'Courier New', monospace;
    font-weight: bold;
  }
  
  /* Error Message */
  .error-message {
    margin-top: 1rem;
    padding: 1rem;
    background-color: var(--error-bg);
    color: var(--error-text);
    border-radius: 8px;
  }
  
  /* Card Animation */
  .card {
    animation: fadeIn 0.8s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  

  .dropdown-container {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  
  .dropdown-btn {
    background-color: var(--button-bg);
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .dropdown-menu {
    background-color: var(--card-bg);
    margin-top: 0.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    position: absolute;
    right: 0;
    z-index: 10;
  }
  
  .dropdown-item {
    padding: 0.75rem 1rem;
    color: var(--primary-text);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .dropdown-item:hover {
    background-color: var(--input-bg);
  }
  
  /* Responsive layout for mobile */
  @media (max-width: 768px) {
    .dropdown-container {
      top: 0.5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  